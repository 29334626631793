// appKey: 'eyJhcHBfYWxpYXMiOiJiYXJ1c2Jha2kiLCJhcHBfbmFtZSI6IkJhcnVzYmFraSIsImNwX2lkIjo1fQ==nqwq37hGg35Okx5dnvSs',
//     secretKey: 'Bismillah',

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import { accessApi, apiPortal, responseApi, toEncrypt } from '@/provider'
// import { toEncrypt } from "../provider";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inboxFitur: true,
    redeemFitur: false,
    storeFitur: true,
    bannerFitur: true,
    isLoading: true,
    user: null,
    profile: null,
    popupShow: false,
    token: null,
    pid: null,
    mno_id: null,
    url: null,
    listgame: [],
    detailgame: {},
    tokenLife: null,
    statusStore: false,
    gameId: null,
    gameIdUnix: null,
    coin: 0,
    point: 0,
    dr: [],
    android:false,
    dataPoint: [],
    reward: null,
    playerData: {},
    mode: 'reg', // mode reg and free
    leaderboardList:[
      // {
      //   msisdn: "62812345xxxx",
      //   point: "10000"
      // },
      // {
      //   msisdn: "62812111xxxx",
      //   point: "9000"
      // },
      // {
      //   msisdn: "62822443xxxx",
      //   point: "8000"
      // },
      // {
      //   msisdn: "62812645xxxx",
      //   point: "7000"
      // },
      // {
      //   msisdn: "62822349xxxx",
      //   point: "6000"
      // },
      // {
      //   msisdn: "62822340xxxx",
      //   point: "5000"
      // },
      // {
      //   msisdn: "62811340xxxx",
      //   point: "4500"
      // },
      // {
      //   msisdn: "62812040xxxx",
      //   point: "3000"
      // },
      // {
      //   msisdn: "62822390xxxx",
      //   point: "2500"
      // },
      // {
      //   msisdn: "62811140xxxx",
      //   point: "1000"
      // },
    ],
    popupReward: [
      
    ],
    inboxList: [
      {
        id: 1,
        name: "Info",
        image: '',
        created: "2021/05/01",
        description: "Selamat Datang di 3ple Hoki game. Mainkan terus gamenya dan dapatkan banyak hadiah menarik untuk kamu",
        url: "#"
      },
    ],
    storeList: [],
    popupToken: {
    },
    appKey: 'eyJhcHBfYWxpYXMiOiJwaXNhdWN1YW4iLCJhcHBfbmFtZSI6IlBpc2F1IEN1YW4iLCJjcF9pZCI6IjEifQ==myxJ6iAqGnCwfLcO',
    secretKey: 'Bismillah',
    telco: null,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  getters: {
    isUser: state => state.user,
    getUser: state => state.user,
    getSecret: state => state.secretKey,
    getGameId: state => state.gameId,
    getLifeId: state => state.tokenLife,
    getCoin: state => state.coin,
    getMode: state => state.mode,
    getRedeem: state => state.redeemList,
    getStore: state => state.storeList,
    getBanner: state => state.bannerList,
    getInbox: state => state.inboxList,
    getPid: state => state.pid,
    getDR: state => state.dr,
    getReward: state => state.popupReward,
    getLeaderboard: state => state.leaderboardList,
    getGameIdUnix: state => state.gameIdUnix,
    getAppkey: state => state.appKey,
    getPopupShow: state => state.popupShow,
    getTelco: state => state.telco,
  },
  mutations: {
    SET_TOKEN: (state, value) => value ? (state.token = value) : (state.token = null),
    SET_USER: (state, value) => value ? (state.user = value) : (state.user = null),
    SET_URL: (state, value) => value ? (state.url = value) : (state.url = null),
    SET_LIST_GAME: (state, value) => value ? (state.listgame = value) : (state.listgame = []), 
    SET_PROFILE: (state, value) => value ? (state.profile = value) : (state.profile = null),
    SET_TOKEN_LIFE: (state, value) => value ? (state.tokenLife = value) : (state.tokenLife = null),
    SET_DATA_POINT: (state, value) => value ? (state.dataPoint = value) : (state.dataPoint = []),
    SET_GAME_ID: (state, value) => value ? (state.gameId = value) : (state.gameId = null),
    SET_DETAIL_GAME: (state, value) => value ? (state.detailgame = value) : (state.detailgame = {}),
    SET_COIN: (state, value) => value ? (state.coin = value) : (state.coin = 0),
    SET_POINT: (state, value) => value ? (state.point = value) : (state.point = 0),
    SET_REWARD: (state, value) => value ? (state.reward = value) : (state.reward = null),
    SET_MODE: (state, value) => value ? (state.mode = value) : (state.mode = 'free'),
    SET_TELCO: (state, value) => value ? (state.telco = value) : (state.telco = null),
    SET_BANNER_LIST: (state, value) => value ? (state.bannerList = value) : (state.bannerList = []),
    SET_REWARD_LIST: (state, value) => value ? (state.popupReward = value) : (state.popupReward = []),
    SET_STORE_LIST: (state, value) => value ? (state.storeList = value) : (state.storeList = []),
    SET_DELIVER_RECHIVE: (state, value) => value ? (state.dr = value) : (state.dr = []),
    SET_LEADERBOARD_LIST: (state, value) => value ? (state.leaderboardList = value) : (state.leaderboardList = []),
    SET_INBOX_LIST: (state, value) => value ? (state.inboxList = value) : (state.inboxList = []),
    SET_GAME_ID_UNIX: (state, value) => value ? (state.gameIdUnix = value) : (state.gameIdUnix = null),
    SET_GAME_POPUP_SHOW: (state, value) => value ? (state.popupShow = value) : (state.popupShow = false),
    SET_PLAYER_DATA: (state, value) => value ? (state.playerData = value) : (state.playerData = {}),
    SET_MNO_ID: (state, value) => value ? (state.mno_id = value) : (state.mno_id = null),
  },
  actions: {
    getProfile: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameProfile', sendData, { headers })
          const dataProfile = await JSON.parse(responseApi(data))
          const JsonDataProfile = dataProfile
          commit('SET_TELCO', JsonDataProfile.telco)
          commit('SET_PROFILE', JsonDataProfile)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getDetail: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameDetail', sendData, { headers })
          // const { data } = await apiPortal.post('getPlayerAppData', sendData, { headers })
          const dataDetail = await JSON.parse(responseApi(data))
          const JsonDataDetail = dataDetail
          commit('SET_COIN', JsonDataDetail.token)
          if (getters.getMode === 'free') {
            JsonDataDetail.point = getters.getPoint
            commit('SET_POINT', JsonDataDetail.point)
          } else {
            commit('SET_POINT', JsonDataDetail.point)
          }
          // console.log(process.env.VUE_APP_KEY, process.env.VUE_APP_SECRET);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getGames: ({commit}) => {
      return new Promise((resolve, reject) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('games', { headers })
        const dataGames = JSON.parse(responseApi(data))
        commit('SET_URL', dataGames[0].link)
        commit('SET_LIST_GAME', dataGames)
        resolve(dataGames);
      }).catch(error => {
        reject(error)
      })
      })
    },
    getPlayerAppData: ({commit}) => {
      return new Promise((resolve, reject) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('getPlayerAppData', { headers })
        const dataGames = data.data
        commit('SET_PLAYER_DATA', dataGames)
        resolve(data);
      }).catch(error => {
        reject(error)
      })
      })
    },
    getLeaderboard: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
        const msisdn = toEncrypt(getters.getUser, getters.getSecret) 
        const sendData = { req: msisdn }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameLeaderboard', sendData, { headers })
        const dataLeaderboard = JSON.parse(responseApi(data))
        commit('SET_LEADERBOARD_LIST', dataLeaderboard)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getService: ({commit}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('getTelcoChannel', { headers })
        commit('SET_STORE_LIST', data.data)
        console.log(data)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getInbox: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 5 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_INBOX _LIST', dataGames)
      })
    },
    getBanner: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 4 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log('data banner',dataGames);
        commit('SET_BANNER_LIST', dataGames)
      })
    },
    getRedeem: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 3 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REDEEM_LIST', dataGames)
      })
    },
    getStore: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 2 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        commit('SET_STORE_LIST', dataGames)
      })
    },
    getReward: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 1 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REWARD_LIST', dataGames)
      })
    },
    getGameReward: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('gameReward', { headers })
        const res = responseApi(data)
        const dataReward = JSON.parse(res)
        // console.log(dataReward);
        commit('SET_REWARD', dataReward)
      })
    },
    getLife: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const unix = Math.round(+new Date()/1000);
          const unixSlice = unix.toString().slice(1, 10);
          const gameUnix = getters.getGameId+parseInt(unixSlice) 
          const datas = {
            game_id: gameUnix,
            msisdn: getters.getUser,
            type: "standalone",
          }
          // console.log('data dikirim', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret) 
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameLife', sendData, { headers })
          const dataLife = await JSON.parse(responseApi(data))
          commit('SET_TOKEN_LIFE', dataLife.token_life)
          commit('SET_GAME_ID_UNIX', gameUnix)
          // console.log('getlife',dataLife);
          resolve(dataLife.token_life);
        }).catch(error => {
          reject(error)
        })
      })
    },
    setPlayerData: async ({commit},datap) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const datas = datap
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('updatePlayerAppData', datas, { headers })
          const dataPlayer = data.data
          commit('SET_PLAYER_DATA', dataPlayer)
          
          resolve(dataPlayer)
        }).catch(error => {
          reject(error)
        })
      })
    },
    storePoint: async ({commit, getters}, point) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const datas = {
            game_id: getters.getGameIdUnix,
            msisdn: getters.getUser,
            token_id: getters.getLifeId,
            data: point,
          }
          // console.log('store data', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret)
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gamePoint', sendData, { headers })
          const dataPoint = await JSON.parse(responseApi(data))
          // console.log('response store', dataPoint)
          commit('SET_DATA_POINT', dataPoint)
          resolve(dataPoint)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deliverRecipe: ({commit},datap) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async () => {
        let urldr= "dr?trx_id=313135353634393433383737&op_id=1&keyword="+datap.marketid+"&msisdn="+datap.msisdn+"&adn=92229&date="+datap.date+"&status=SUCCESS&subject=MT;PULL;SMS;TEXT"
        const { data } = await apiPortal.post(urldr)
        commit('SET_DELIVER_RECHIVE', data)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSubscriptionStatusByFakeId: ({commit,getters}) => {
      // const sendData = { fake_id: getters.getPid , app_id: 16}
      // apiPortal.post('getSubscriptionStatusByFakeId', sendData).then((response) => {
      //   const dataUser=response.data.data;
      //   console.log(dataUser)
      //   commit('SET_USER', dataUser.msisdn)
      //   commit('SET_MNO_ID', dataUser.mno_id)
      //   commit('SET_TELCO', 'smart')
      //   console.log('lihat:',getters.getUser)
      // })
      return new Promise((resolve, reject) => {
        const sendData = { fake_id: getters.getPid , app_id: 16}
        apiPortal.post('getSubscriptionStatusByFakeId', sendData).then(async(response) => {
        const dataUser=response.data.data;
        commit('SET_USER', String(dataUser.msisdn))
        commit('SET_MNO_ID', String(dataUser.mno_id))
        commit('SET_TELCO', 'smart')
        resolve(dataUser)
      }).catch(error => {
        reject(error)
      })

      })
    },
  },
  modules: {
    // login
  }
});

